@import "../../abstracts.scss";

.hero {
  @include fullAbsolute;
  z-index: 1;
  padding: 20px var(--layout-vertical-padding);
  overflow: hidden;
}

.heroBg {
  @include fullAbsolute;
  clip-path: inset(0 100% 0 0);
  transform: scale3d(0.5, 0.5, 0.5);
  animation: heroBgAreaIn 0.7s var(--primary-curve) forwards;

  @include after {
    @include fullAbsolute;
    @include blendBackground;
    background-image: url("/assets/images/hero-background.jpg");
    background-position: 35% 0;
    background-size: cover;
    transform: var(--hero-bg-transform);
    animation: heroBgImageIn 0.5s var(--secondary-curve) 2.2s forwards;
  }
}

.heroContentArea {
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
}

.heroContent {
  @include useAppArea;
  text-align: center;
}

.caption {
  margin-top: 60px;
  padding: 0 var(--layout-vertical-padding);
  color: var(--main-white);
  font-size: var(--title-1);
  overflow: hidden;

  & > span {
    display: inline-block;
    transform: translate3d(0, 100%, 0);
    animation: captionIn 0.5s var(--primary-curve) 0.9s forwards;
  }
}

.subCaption {
  margin: 20px 0 20px;
  color: var(--main-white);
  font-size: var(--title-5);
  font-weight: 600;
  overflow: hidden;

  @media screen and (min-width: #{$screen-medium}) {
    margin: 20px 0 30px;
  }

  & > span {
    display: inline-block;
    transform: translate3d(0, 100%, 0);
    animation: captionIn 0.5s var(--primary-curve) 1.2s forwards;
  }
}

@keyframes heroBgAreaIn {
  to {
    clip-path: inset(0 0 0 0);
    transform: var(--scale-base);
  }
}

@keyframes heroBgImageIn {
  to {
    transform: translate3d(0, 70px, 0);
  }
}

@keyframes captionIn {
  to {
    transform: var(--translate-base);
  }
}
