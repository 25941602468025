// BREAKPOINTS
$screen-small: 576px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-extra-large: 1200px;

// MIXINS
@mixin useAppArea {
  max-width: var(--app-area-width);
  margin: auto;
}

@mixin fullFixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin fullAbsolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin blendBackground {
  background-color: var(--brand-grey-transparent);
  background-blend-mode: overlay;
}

@mixin blendOverlay {
  background: var(--brand-grey-transparent);
  mix-blend-mode: overlay;
}

@mixin before($content: "") {
  &:before {
    content: $content;
    @content;
  }
}

@mixin after($content: "") {
  &:after {
    content: $content;
    @content;
  }
}
