@import "../../abstracts.scss";

.logo {
  position: relative;
  display: inline-block;
  color: var(--brand-primary);
  font-family: "Reenie Beanie";
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;

  @media screen and (min-width: #{$screen-medium}) {
    font-size: 30px;
  }

  &.inverse {
    color: var(--main-white);
  }
}

.stories {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  font-family: "Heebo";
  font-size: 10px;
  display: inline-block;

  @media screen and (min-width: #{$screen-medium}) {
    font-size: 12px;
  }
}
