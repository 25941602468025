@import "../../abstracts.scss";

.qualityTime {
  position: relative;
  padding: 50px var(--layout-vertical-padding);

  & > div {
    @include useAppArea;
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;

    @media screen and (min-width: #{$screen-large}) {
      grid-template-columns: repeat(2, 1fr);
    }

    & > div:nth-child(2) {
      grid-row-start: 1;

      @media screen and (min-width: #{$screen-large}) {
        grid-row-start: initial;
      }
    }
  }
}

.columnTwoContents {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: #{$screen-large}) {
    position: sticky;
    top: 0px;
    height: 800px;
  }
}

.details {
  text-align: center;

  @media screen and (min-width: #{$screen-large}) {
    text-align: initial;
  }

  &.isActive {
    .title,
    .description {
      transform: var(--translate-base);
      transition: transform 0.7s var(--secondary-curve);
    }

    .description {
      transition-duration: 0.8s;
    }
  }
}

.title {
  font-size: var(--title-4);
  line-height: 1.35;
  margin-bottom: 20px;
  transform: translate3d(0, 60px, 0);
}

.description {
  font-size: var(--paragraph-1);
  line-height: 1.25;
  transform: translate3d(0, 80px, 0);
}

.time {
  margin-top: 100px;
  font-size: 90px;
  font-weight: 400;
  line-height: 1;
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
  }

  span {
    display: inline-block;
  }

  &.isActive {
    .day,
    .hourTime {
      transform: var(--translate-base);
      transition: transform 0.7s var(--secondary-curve);
    }

    .hourTime {
      transition-duration: 0.8s;
    }
  }
}

.day {
  position: relative;
  top: 8px;
  font-size: 50px;
  line-height: 1;
  transform: translate3d(0, 60px, 0);
  overflow: hidden;
}

.dayBlock {
  transition: transform 0.5s var(--secondary-curve);

  &.isActive,
  &.isInActive {
    transform: translate3d(0, -105%, 0);
  }
}

.dayBlockLater {
  position: absolute;
  top: 100%;
  left: 0;
}

.hourTime {
  transform: translate3d(0, 80px, 0);
  position: relative;
  overflow: hidden;
}

.hourTimeChar {
  transition: transform 0.3s var(--secondary-curve);

  @for $i from 0 through 3 {
    &:nth-child(#{$i + 1}) {
      transition-delay: $i * 0.1s;
    }
  }
}

.hourBlock {
  &.isActive,
  &.isInActive {
    & > .hourTimeChar {
      transform: translate3d(0, -100%, 0);
    }
  }
}

.hourBlockLater {
  position: absolute;
  top: 100%;
  left: 0;

  &.isInActive {
    & > .hourTimeChar {
      transform: translate3d(0, -200%, 0);
    }
  }
}

.timePeriod {
  position: relative;
  margin-left: 20px;
}

.timePeriodChar {
  transition: transform 0.3s var(--secondary-curve);

  @for $i from 0 through 1 {
    &:nth-child(#{$i + 1}) {
      transition-delay: 0.5s + ($i * 0.1s);
    }
  }
}

.periodBlock {
  &.isActive,
  &.isInActive {
    & > .timePeriodChar {
      transform: translate3d(0, -100%, 0);
    }
  }
}

.periodBlockLater {
  position: absolute;
  width: 150px;
  top: 100%;
  left: 0;
}

.cover {
  position: relative;
  width: 100%;
  height: 150vw;
  max-width: calc(100vw - (var(--layout-vertical-padding) * 2));
  max-height: 90vh;
  margin: 0 auto var(--quality-time-cover-margin);

  @media screen and (min-width: #{$screen-large}) {
    height: 800px;
  }

  @include after {
    @include fullAbsolute;
    @include blendBackground;
    background-size: cover;
    clip-path: inset(100% 0 100% 0);
    transform: scale3d(0.5, 0.5, 0.5);
  }

  &.isActive {
    &:after {
      clip-path: inset(0 0 0 0);
      transform: var(--scale-base);
      transition: transform 1.1s var(--secondary-curve), clip-path 0.7s var(--primary-curve);
    }
  }
}

.coverOne {
  &:after {
    background-image: url("/assets/images/looking-at-vinyl.jpg");
  }
}

.coverTwo {
  &:after {
    background-image: url("/assets/images/dancing-to-tunes.jpg");
  }
}

.videoCover {
  position: relative;
  width: 100%;
  height: 150vw;
  max-width: calc(100vw - (var(--layout-vertical-padding) * 2));
  max-height: 90vh;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (min-width: #{$screen-large}) {
    height: 800px;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  & > div {
    @include fullAbsolute;
    clip-path: inset(100% 0 100% 0);
    transform: scale3d(0.5, 0.5, 0.5);
  }

  &.isActive > div {
    clip-path: inset(0 0 0 0);
    transform: var(--scale-base);
    transition: transform 1.1s var(--secondary-curve), clip-path 0.7s var(--primary-curve);
  }
}
