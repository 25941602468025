@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;700&family=Reenie+Beanie&display=swap");
@import "./abstracts.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &:before,
  &:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  font-family: "Heebo";
  color: var(--main-black);
  background-color: var(--main-white);
}

img {
  max-width: 100%;
}

blockquote {
  p span {
    @include before(open-quote);
    @include after(close-quote);
  }
}

:root {
  --app-area-width: 1280px;
  --layout-vertical-padding: 20px;

  --spring-image-dimension: 200px;
  --quality-time-cover-margin: 50px;

  --title-1: 50px;
  --title-2: 40px;
  --title-3: 36px;
  --title-4: 32px;
  --title-5: 20px;
  --title-6: 18px;
  --paragraph-1: 18px;
  --paragraph-2: 16px;
  --testimonial-quote: 18px;

  --main-white: #ffffff;
  --main-black: #000000;
  --main-black-alt: #242424;
  --main-white-transparent: rgba(255, 255, 255, 0.2);
  --brand-primary: #221818;
  --brand-light: #322424;
  --brand-grey-light: #f8f6f6;
  --brand-grey-light-2: #e2d6d6;
  --brand-grey-transparent: rgba(50, 36, 36, 0.14);

  --transform-base: translate3d(0, 0, 0) scale3d(1, 1, 1);
  --translate-base: translate3d(0, 0, 0);
  --scale-base: scale3d(1, 1, 1);
  --hero-bg-transform: scale3d(1.05, 1.05, 1.05) translate3d(0, 0, 0);

  --primary-curve: cubic-bezier(0.25, 0.46, 0.32, 0.94);
  --secondary-curve: cubic-bezier(0.43, 0.99, 0.49, 0.98);

  @media screen and (min-width: #{$screen-small}) {
    --testimonial-quote: 32px;
  }

  @media screen and (min-width: #{$screen-medium}) {
    --layout-vertical-padding: 40px;

    --spring-image-dimension: 280px;

    --title-1: 70px;
    --title-2: 60px;
    --title-3: 54px;
    --title-4: 40px;
    --title-5: 30px;
    --title-6: 24px;
    --paragraph-1: 22px;
    --paragraph-2: 20px;
  }

  @media screen and (min-width: #{$screen-large}) {
    --quality-time-cover-margin: 100px;

    --testimonial-quote: 44px;
  }

  @media screen and (min-width: #{$screen-extra-large}) {
    --title-1: 80px;
    --title-5: 32px;
  }
}
