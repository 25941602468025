@import "../../abstracts.scss";

.testimonials {
  padding: 0 var(--layout-vertical-padding) 70px;
  text-align: center;

  @media screen and (min-width: #{$screen-large}) {
    padding-top: 20px;
  }

  p {
    font-size: var(--testimonial-quote);
    line-height: 1.35;
    font-weight: 600;
    transform: translate3d(0, 60px, 0);

    .activeBlockItem {
      transition: 0.5s var(--primary-curve);
    }
  }

  figcaption {
    margin-top: 20px;
    font-family: "Reenie Beanie";
    font-size: 36px;
    font-weight: 600;
    transform: translate3d(0, 80px, 0);

    @media screen and (min-width: #{$screen-large}) {
      margin-top: 30px;
    }

    .activeBlockItem {
      transition: 0.65s var(--primary-curve);
    }
  }

  &.isActive {
    p,
    figcaption,
    .testimonialLogo {
      transform: var(--translate-base);
      transition: transform 0.7s var(--primary-curve);
    }

    figcaption {
      transition-duration: 0.8s;
    }

    .testimonialLogo {
      transition-duration: 0.85s;
    }
  }
}

.flipArea {
  display: flex;
  justify-content: center;
  height: 1.2em;
  overflow: hidden;
  position: relative;

  & > span {
    display: block;
    position: absolute;
    transform: translate3d(0, 100%, 0);
  }

  .activeBlockItem {
    transform: var(--translate-base);
  }
}

.testimonialsCarousel {
  @include useAppArea;
  background: var(--brand-grey-light);
  padding: 40px 5px 30px;

  @media screen and (min-width: #{$screen-medium}) {
    padding: 70px 50px 50px;
  }
}

.carouselRings {
  margin-top: 40px;

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 70px;
  }
}

.carouselRing {
  margin: 0 15px;
  transform: rotate(-90deg) scale(0.8);
  transition: 0.4s var(--primary-curve);

  &.activeRing {
    transform: rotate(-90deg) scale(1);

    .backgroundRing {
      opacity: 0.3;
    }

    .progressRing {
      animation: progressFill 8s linear;
    }
  }
}

.backgroundRing {
  stroke: var(--brand-primary);
  opacity: 0.25;
  transition: 0.3s var(--primary-curve);
}

.progressRing {
  stroke: var(--brand-primary);
  stroke-dashoffset: 80px;
}

.testimonialLogo {
  margin-top: 20px;
  transform: translate3d(0, 100px, 0);

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 35px;
  }

  &.flipArea {
    height: 28px;
  }

  .activeBlockItem {
    transition: 0.8s var(--primary-curve);
  }

  svg {
    height: 26px;
    width: auto;
  }
}

@keyframes progressFill {
  to {
    stroke-dashoffset: 5px;
  }
}
