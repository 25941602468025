@import "../../abstracts.scss";

.join {
  position: relative;
  background: var(--brand-grey-light);
  padding: 0 var(--layout-vertical-padding);
  overflow: hidden;

  &.isActive {
    .logo,
    .title,
    .actionButton {
      transform: var(--translate-base);
      transition: transform 0.7s var(--primary-curve);
    }

    title {
      transition-duration: 0.8s;
    }
  }
}

.joinGrid {
  @include useAppArea;
  padding: 80px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  align-items: center;
  text-align: center;

  @media screen and (min-width: #{$screen-medium}) {
    padding: 100px 0;
  }

  @media screen and (min-width: #{$screen-large}) {
    padding: 50px 0;
    grid-template-columns: 2fr 1fr;
    text-align: initial;
  }
}

.logo {
  transform: translate3d(0, 60px, 0);
}

.title {
  margin-top: 10px;
  font-size: var(--title-3);
  line-height: 1.1;
  font-weight: 600;
  transform: translate3d(0, 80px, 0);
}

.actionButton {
  margin-top: 40px;
  transform: translate3d(0, 100px, 0);
}
