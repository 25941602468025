@import "../../abstracts.scss";

.videoPlayer {
  @include fullFixed;
  z-index: 100;
  background: var(--main-black);
}

.videoWrapper {
  transform: var(--translate-base);
  animation: swipeIn 0.2s var(--primary-curve);

  video {
    width: 100vw;
    height: 100vh;
  }
}

.isControlsActive {
  .close,
  .controls,
  .playButton,
  .progressBar {
    transform: var(--translate-base);
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  display: inline-block;
  z-index: 10;
  transform: translate3d(0, -100px, 0);
  transition: 0.5s var(--secondary-curve);
  cursor: pointer;

  &:hover {
    svg {
      transform: scale3d(1.04, 1.04, 1.04);
    }
  }

  svg {
    width: 32px;
    height: 32px;
    transform: var(--scale-base);
    transition: 0.3s var(--primary-curve);
  }
}

.controls {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  transform: translate3d(0, 200px, 0);
  transition: 0.5s var(--secondary-curve);
}

.progressBar {
  position: relative;
  width: 1000px;
  height: 4px;
  max-width: calc(100% - 180px);
  transform: translate3d(0, 100px, 0);
  transition: 0.65s var(--secondary-curve);

  @include after {
    @include fullAbsolute;
    background: var(--main-white-transparent);
  }
}

.currentProgress {
  position: relative;
  @include fullAbsolute;
  background: var(--main-white);
  transform: scale3d(0, 1, 1);
  transform-origin: left;
  z-index: 5;
}

.playButton {
  margin-right: 30px;
  transform: translate3d(0, 100px, 0);
  transition: 0.55s var(--secondary-curve);
}

@keyframes swipeIn {
  from {
    transform: translate3d(0, 20px, 0);
  }
}
