@import "../../abstracts.scss";

.button {
  display: inline-flex;
  align-items: center;
  text-align: center;
  user-select: none;
  border: 0;
  border-radius: 30px;
  box-shadow: none;
  padding: 10px 20px;
  background: var(--main-black);
  font-size: 14px;
  line-height: 1.25;
  color: var(--main-white);
  font-family: "Heebo";
  cursor: pointer;
  transition: 0.3s var(--primary-curve);

  @media screen and (min-width: #{$screen-medium}) {
    padding: 15px 25px;
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background: var(--main-black-alt);
  }
}

.sm {
  font-size: 12px;
  line-height: 1.35;
  padding: 10px 15px;

  @media screen and (min-width: #{$screen-medium}) {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.lg {
  font-size: 16px;
  line-height: 1.35;
  padding: 20px 45px;
  border-radius: 80px;
}

.buttonIcon {
  display: inline-flex;
  align-items: center;

  svg {
    width: 1.3em;
    height: 1.3em;
    fill: currentColor;

    @media screen and (min-width: #{$screen-medium}) {
      width: 1.6em;
      height: 1.6em;
    }
  }
}

.playButton {
  padding: 18px;
  background: var(--brand-primary);
  transform: var(--scale-base);
  clip-path: circle(50%);
  transition: 0.3s var(--primary-curve);
  animation: playButtonIn 0.3s var(--primary-curve);

  @media screen and (min-width: #{$screen-medium}) {
    padding: 25px;
  }

  .buttonIcon {
    transform: var(--scale-base);
    transition: 0.4s var(--primary-curve);
    animation: playButtonIconIn 0.7s var(--secondary-curve);

    svg:nth-child(1) {
      transform: var(--transform-base);
      transition: 0.3s var(--secondary-curve);
    }

    svg:nth-child(2) {
      position: absolute;
      transform: translate3d(0, 220%, 0);
    }
  }

  &:hover {
    background: var(--brand-primary);
    transform: scale3d(1.05, 1.05, 1.05);

    .buttonIcon {
      transform: scale3d(1.4, 1.4, 1.4);
    }
  }

  &.sm {
    font-size: 12px;
    padding: 12px;
  }

  &:focus {
    background: var(--brand-light);
  }
}

.playButtonInverse {
  color: var(--brand-primary);
  background: var(--main-white);

  &:hover,
  &:focus {
    background: var(--main-white);
  }
}

.isPlaying {
  .buttonIcon {
    svg:nth-child(1) {
      transform: translate3d(0, 220%, 0);
      transition: 0s;
    }

    svg:nth-child(2) {
      transform: var(--transform-base);
      transition: 0.3s var(--secondary-curve);
    }
  }
}

@keyframes playButtonIn {
  from {
    clip-path: circle(0);
  }
}

@keyframes playButtonIconIn {
  from {
    transform: scale3d(1.5, 1.5, 1.5);
  }
}
