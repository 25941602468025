@import "../../abstracts.scss";

.passion {
  padding: 50px var(--layout-vertical-padding) 40px;

  @media screen and (min-width: #{$screen-medium}) {
    padding: 100px var(--layout-vertical-padding) 60px;
  }
}

.title {
  @include useAppArea;
  text-align: center;
  font-size: var(--title-2);
  line-height: 1.35;
  margin-bottom: 40px;

  @media screen and (min-width: #{$screen-medium}) {
    margin-bottom: 60px;
  }

  & > span {
    display: inline-block;
    transform: translate3d(0, 100px, 0);
  }

  &.isActive {
    & > span {
      transform: var(--translate-base);
      transition: transform 0.7s var(--secondary-curve);
    }
  }
}

.passionCover {
  position: relative;
  width: 1280px;
  height: 150vw;
  max-width: calc(100vw - (var(--layout-vertical-padding) * 2));
  max-height: 90vh;
  margin: auto;

  @media screen and (min-width: #{$screen-medium}) {
    height: 800px;
  }

  @include after {
    @include fullAbsolute;
    @include blendBackground;
    background-image: url("/assets/images/passion.jpg");
    background-size: cover;
    background-position: center;
    clip-path: inset(0 0 100% 0);
    transform: scale3d(0.5, 0.5, 0.5);
  }

  &.isActive {
    &:after {
      clip-path: inset(0 0 0 0);
      transform: var(--scale-base);
      transition: transform 1.1s var(--secondary-curve), clip-path 0.7s var(--primary-curve);
    }
  }
}
