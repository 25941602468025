@import "../../abstracts.scss";

.header {
  background-color: var(--main-white);
  position: relative;
  z-index: 10;
  clip-path: inset(0 0 100% 0);
  animation: headerIn 0.5s var(--secondary-curve) 2.2s forwards;
}

.headerNav {
  @include useAppArea;
  padding: 10px var(--layout-vertical-padding);
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  transform: translate3d(0, -100px, 0);
  animation: itemDrop 0.5s var(--secondary-curve) 2.4s forwards;
}

.menu {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  @media screen and (min-width: #{$screen-medium}) {
    justify-content: space-between;
  }

  @media screen and (min-width: #{$screen-large}) {
    width: 60%;
  }

  ul {
    display: flex;
    list-style-type: none;
    width: 0;
    overflow: hidden;

    @media screen and (min-width: #{$screen-medium}) {
      width: auto;
    }

    li {
      margin: 30px;
      cursor: pointer;
      transform: translate3d(0, -100px, 0);
      animation: itemDrop 0.5s var(--secondary-curve) 2.6s forwards;

      @for $i from 0 through 2 {
        &:nth-child(#{$i + 1}) {
          animation-delay: 2.5s + ($i * 0.07);
        }
      }
    }
  }
}

.accountButton {
  transform: translate3d(0, -100px, 0);
  animation: itemDrop 0.5s var(--secondary-curve) 2.5s forwards;

  @media screen and (min-width: #{$screen-medium}) {
    animation-delay: 2.8s;
  }
}

@keyframes headerIn {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes itemDrop {
  to {
    transform: var(--translate-base);
  }
}
