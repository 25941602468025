@import "../../abstracts.scss";

.nextStory {
  padding: 80px var(--layout-vertical-padding);

  @media screen and (min-width: #{$screen-large}) {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}

.nextStoryGrid {
  @include useAppArea;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  text-align: center;

  @media screen and (min-width: #{$screen-large}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    text-align: initial;
  }

  &.isActive {
    .subTitle,
    .title,
    .collaborator {
      transform: var(--translate-base);
      transition: transform 0.7s var(--primary-curve);
    }

    title {
      transition-duration: 0.8s;
    }
  }
}

.details {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (min-width: #{$screen-large}) {
    padding: 20px 0;
  }
}

.subTitle {
  font-size: var(--title-6);
  font-weight: 600;
  transform: translate3d(0, 60px, 0);
}

.title {
  max-width: 400px;
  margin: 10px auto 0;
  font-size: var(--title-3);
  line-height: 1.1;
  font-weight: 600;
  transform: translate3d(0, 80px, 0);
  cursor: pointer;

  @media screen and (min-width: #{$screen-medium}) {
    max-width: 600px;
  }

  @media screen and (min-width: #{$screen-large}) {
    margin: 10px 0 0;
  }

  &:hover > span {
    color: var(--brand-light);
  }

  & > span {
    display: inline-block;
    transition: 0.2s var(--primary-curve);
  }
}

.collaborator {
  margin-top: 40px;
  color: var(--brand-primary);
  font-size: var(--paragraph-2);
  transform: translate3d(0, 100px, 0);

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 0;
  }
}

.collaboratorLogo {
  display: inline-block;
  margin-top: 20px;

  svg {
    width: auto;
    height: 30px;

    @media screen and (min-width: #{$screen-medium}) {
      height: 40px;
    }
  }
}

.covers {
  display: grid;
  grid-template-columns: 1fr 0.95fr;
  grid-template-rows: repeat(2, 200px);
  gap: 30px;

  &.isActive {
    .cover {
      @for $i from 0 through 2 {
        &:nth-child(#{$i + 1}) {
          &:after {
            transition-delay: ($i * 0.05s);
          }
        }
      }
      &:after {
        clip-path: inset(0 0 0 0);
        transform: var(--scale-base);
        transition: transform 1.1s var(--secondary-curve), clip-path 0.7s var(--primary-curve);
      }
    }
  }
}

.cover {
  position: relative;
  clip-path: inset(0 0 0 0);
  transition: 0.2s var(--primary-curve);

  @include after {
    @include fullAbsolute;
    @include blendBackground;
    background-size: cover;
    background-position: center;
    clip-path: inset(100% 0 100% 0);
    transform: scale3d(0.5, 0.5, 0.5);
  }
}

.coverOne {
  &:after {
    background-image: url("/assets/images/flick-1.jpg");
  }
}

.coverTwo {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;

  &:after {
    background-image: url("/assets/images/flick-2.jpg");
  }
}

.coverThree {
  &:after {
    background-image: url("/assets/images/flick-3.jpg");
  }
}
