@import "../../abstracts.scss";

.footer {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 0px;
  background-color: var(--brand-light);
  z-index: 5;
  animation: footerIn 0s linear 3.5s forwards;
}

.footerNav {
  @include useAppArea;
  padding: 40px var(--layout-vertical-padding);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (min-width: #{$screen-medium}) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > div {
    grid-column-start: 1;
    grid-column-end: 3;

    @media screen and (min-width: #{$screen-medium}) {
      grid-column-start: initial;
      grid-column-end: initial;
    }
  }
}

.menuColumn {
  list-style-type: none;
  font-size: 16px;

  li {
    margin: 15px 0;
    color: var(--brand-grey-light-2);
    transition: 0.2s ease;

    span {
      cursor: pointer;
    }

    &:hover {
      color: var(--main-white);
    }
  }
}

@keyframes footerIn {
  to {
    height: auto;
    overflow: initial;
  }
}
