.offScreen {
  position: relative;
  overflow: hidden;
  height: 0px;
  background: var(--main-white);
  z-index: 10;
  animation: offScreenIn 0s linear 3.5s forwards;
  transform: translateZ(0);
}

@keyframes offScreenIn {
  to {
    height: auto;
    overflow: initial;
  }
}
