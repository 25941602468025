@import "../../abstracts.scss";

.photosSpring {
  height: var(--spring-image-dimension);
  display: flex;
  justify-content: center;
}

.hotspot {
  position: absolute;
  width: 800px;
  height: 360px;
  transform: translate(40px, -40px);
  z-index: 10;
  animation: hotspotIn 3s;
}

.photoContainer {
  position: absolute;
  width: var(--spring-image-dimension);
  height: var(--spring-image-dimension);
  transform: translate3d(200px, 0, 0) scale3d(0, 0, 0);
  animation: photoIn 0.5s var(--primary-curve) forwards;

  @for $i from 0 through 5 {
    &:nth-child(#{$i + 1}) {
      z-index: 5 - $i;
      animation-delay: $i * 0.1s;

      & > .photo {
        transform: translate3d($i * 40px, 0, 0) scale3d(1 - (0.1 * $i), 1 - (0.1 * $i), 1 - (0.1 * $i));
      }
    }
  }
}

.photo {
  position: absolute;
  width: var(--spring-image-dimension);
  height: var(--spring-image-dimension);
  border-radius: 50%;
  overflow: hidden;

  @include after {
    @include fullAbsolute;
    background-size: cover;
  }

  @include before {
    @include fullAbsolute;
    @include blendOverlay;
    z-index: 1;
  }
}

.pearl {
  @include after {
    background-image: url("/assets/images/customer-pearl.jpg");
  }
}

.emerald {
  @include after {
    background-image: url("/assets/images/customer-emerald.jpg");
    background-position: top;
  }
}

.diamond {
  @include after {
    background-image: url("/assets/images/customer-diamond.jpg");
    background-position: 0 65%;
  }
}

.ruby {
  @include after {
    background-image: url("/assets/images/customer-ruby.jpg");
  }
}

.jade {
  @include after {
    background-image: url("/assets/images/customer-jade.jpg");
    background-position: 0 80%;
  }
}

.amber {
  @include after {
    background-image: url("/assets/images/customer-amber.jpg");
    background-position: 45% 0;
  }
}

.turquoise {
  @include after {
    background-image: url("/assets/images/customer-turquoise.jpg");
    background-position: 40% 0;
  }
}

.sapphire {
  @include after {
    background-image: url("/assets/images/customer-sapphire.jpg");
    background-position: 0 65%;
  }
}

.opal {
  @include after {
    background-image: url("/assets/images/customer-opal.jpg");
    background-position: 0 50%;
  }
}

@keyframes hotspotIn {
  from {
    pointer-events: none;
  }
}

@keyframes photoIn {
  to {
    transform: var(--transform-base);
  }
}
